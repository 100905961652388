.alert-message {
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}
.alert-notice {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
  .alert-message {
    @include alert-variant($alert-danger-bg, transparent, $alert-danger-text);
  }
}
.alert-error {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
  .alert-message {
    @include alert-variant($alert-danger-bg, transparent, $alert-danger-text);
  }
}