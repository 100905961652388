.wrapper-quickorder {
  @media (min-width: 768px) {
    .modal-sm {
      width: 480px;
    }
  }
  .modal-dialog {
    .modal-content {
      text-align: left;
      border: 1px solid #aaa;
      .modal-header {
        text-align: left;
        padding: 37px 0 7px 102px;
        .modal-title {
          font-size: 32px;
          color: #4859a5;
        }
      }
      .modal-body {
        padding: 18px 48px 38px 102px;
        .quick-name-product {
          color: #010101;
          font-size: 18px;
          margin-bottom: 24px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: 64px;
            height: 2px;
            display: block;
            background: #000;
            left: -74px;
            top: calc(50% - 1px);
          }
        }
        .pad-quick {
          padding-bottom: 0;
          margin-bottom: 20px;
          position: relative;
          display: flex;
          align-items: baseline;
          .quick-label {
            font-size: 14px;
            color: #000;
            flex-basis: 77px;
            &:after {
              content: '';
              width: 40px;
              height: 1px;
              background: #000;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
          .quick-input {
            flex-basis: 246px;
          }
        }
        .checkbox {
          margin-top: 34px;
          margin-bottom: 20px;
        }
        .box-quick-order {
          display: inline-block;
          position: relative;
          transition: color .2s ease-out 0s;
          label {
            overflow: hidden;
            display: inline-block;
            position: relative;
            padding: 0 0 7px;
            &:before {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 68px;
              height: 1px;
              background-color: #000;
              transition: all 0.4s ease;
            }
            &:hover {
              &:before {
                left: 100%;
                background-color: #4859a5;
                transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
              }
            }
          }
          input[type="submit"] {
            background-color: transparent;
            border-color: transparent;
            font-size: 16px;
            font-weight: 300;
            color: #000;
            padding: 0;
            border: none;
            &:hover {
              color: #4859a5;
            }
          }
          &:after {
            font-family: "metpromstroy" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\70";
            font-size: 24px;
            color: #000;
            position: relative;
            top: -10px;
            right: -7px;
            transition: right 0.2s ease-out 0s;
          }
          &:hover {
            color: #4859a5;
            transition: color 0s ease-in 0.3s;
            &:after {
              right: -10px;
              transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s;
            }
          }
        }
      }
      &:hover {
        cursor: default;
      }
    }
  }
  &:hover {
    cursor: default;
  }
}

.fielderror {
  border-color: #ff0000 !important;
  + a {
    color: #ff0000 !important;
  }
}

#modal-messages {
  background: #fff;
  #messages-header {
    padding: 18px;
    .close {
      opacity: 1;
      i {
        font-size: 24px;
      }
    }
  }
  #messages-main {
    padding: 10px 50px 25px 50px;
    text-align: left;
    .modal-title {
      line-height: normal;
      font-size: 26px;
      padding: 100px 0 120px 0;
      color: #4859a5;
    }
    .thank-img {
      padding-top: 25px;
    }
    #messages-close {
      display: inline-block;
      position: relative;
      transition: color .2s ease-out 0s;
      a {
        color: #000;
        font-size: 16px;
        text-decoration: none !important;
        display: inline-block;
        position: relative;
        transition: color 0.2s ease-out 0s;
        &:after {
          font-family: "metpromstroy" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\70";
          font-size: 24px;
          color: #000;
          position: relative;
          top: -15px;
          right: -7px;
          transition: right 0.2s ease-out 0s;
        }
        &:hover {
          color: #4859a5;
          transition: color 0s ease-in 0.3s;
          &:after {
            right: -10px;
            transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s;
          }
        }
        span {
          overflow: hidden;
          display: inline-block;
          position: relative;
          padding: 0 0 7px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 51px;
            height: 1px;
            background-color: #000;
            transition: all 0.4s ease;
          }
          &:hover {
            &:before {
              left: 100%;
              background-color: #00b7f4;
              transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
            }
          }
        }
      }
    }
  }
}