.jshop_list_category {
  .list_category {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: stretch;
    .row-fluid {
      width: calc(100% / 3);
      padding-top: 45px;
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      .row-flex-wrap {

      }
      .jshop_categ {
        width: 100%;
        .sblock2 {
          padding-bottom: 50px;
          .category_name {
            text-align: center;
            &:after {
              border-bottom: 6px solid #85a1ca;
              content: '';
              display: block;
              padding-top: 5px;
            }
            a {
              color: #000;
              font-size: 24px;
              &:hover {
                text-decoration: none;
                color: #000;
              }
            }
            &:hover {
              &:after {
                border-bottom: 6px solid #6186bb;
                content: '';
                display: block;
              }
            }
          }
        }
      }
    }
  }
}