footer {
  box-shadow: 0px -13px 8px -5px rgba(0, 0, 0, 0.5);
  > div {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    .block-contacts {
      flex-basis: 33.33%;
      padding: 0 15px;
      h3 {
        color: #4859a5;
        font-size: 18px;
        position: relative;
        margin-top: 40px;
        margin-bottom: 25px;
        padding-bottom: 10px;
        &:after {
          content: '';
          width: 68px;
          height: 1px;
          background: #000;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      p {
        font-size: 16px;
        text-indent: 0;
        color: #00b7f4;
        margin: 0;
      }
      span {
        font-size: 16px;
      }
      a {
        font-size: 16px;
        display: block;
        color: #000;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .block-manager {
      flex-basis: 33.33%;
      padding: 0 15px;
      h3 {
        color: #4859a5;
        font-size: 18px;
        position: relative;
        margin-top: 40px;
        margin-bottom: 25px;
        padding-bottom: 10px;
        &:after {
          content: '';
          width: 68px;
          height: 1px;
          background: #000;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      p {
        font-size: 16px;
        text-indent: 0;
        color: #00b7f4;
        margin: 0;
      }
      span {
        font-size: 16px;
        display: block;
      }
      a {
        font-size: 16px;
        display: block;
        color: #000;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .block-footer-menu {
      flex-basis: 33.33%;
      padding: 0 15px;
      h3 {
        color: #4859a5;
        font-size: 18px;
        position: relative;
        margin-top: 40px;
        margin-bottom: 25px;
        padding-bottom: 10px;
        &:after {
          content: '';
          width: 68px;
          height: 1px;
          background: #000;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      p {
        font-size: 16px;
        text-indent: 0;
        color: #00b7f4;
        margin: 0;
      }
      span {
        font-size: 16px;
      }
      .nav {
        padding: 0;
        > li {
          > a {
            padding: 0 0 5px 0;
            color: #000;
            font-size: 16px;
            &:hover {
              background-color: transparent;
              color: #00b7f4;
            }
          }
          &:hover {
            background-color: transparent;
            color: #00b7f4;
          }
        }
      }
    }
    .item {
      width: calc((100% / 3) - 15px);
      color: #000;
      font-size: 12px;
      padding: 35px 15px 10px 15px;
      a {
        color: #000;
      }
      i {
        color: #000;
        font-size: 20px;
        position: relative;
        top: 5px;
        padding-right: 7px;
      }
    }
  }
}