#question, .questions-module {
  background: url("/templates/tradeindustriya/images/bg_question.jpg") center center no-repeat;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 40px;
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .form-question {
    padding-top: 50px;
    p {
      text-indent: 0;
    }
    .form-group {
      .form-control {
        font-size: 16px;
        color: #9298a0;
        padding: 14px 18px;
        height: 47px;
      }
      input::-webkit-input-placeholder {
        color: #9298a0;
      }
      input:-moz-placeholder {
        color: #9298a0;
      }
      input::-moz-placeholder {
        color: #9298a0;
      }
      input:-ms-input-placeholder {
        color: #9298a0;
      }
    }
    .btn-submit-form {
      height: 47px;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 1;
      background: #2b3542;
      &:hover {
        background: darken(#2b3542, 10%);
      }
      @media (max-width: 991px) {
        font-size: 14px;
        height: 49px;
      }
    }
    a {
      color: #fff;
    }
    .info-send {
      color: #fff;
      font-size: 18px;
      text-align: center;
      p {
        margin: 0;
        text-indent: 0;
      }
    }
  }
  &.questions-module {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    .btn-submit-form {
      font-size: 14px;
    }
  }
}