#header {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .5);
  .top-header {
    display: flex;
    align-items: center;
    min-height: 72px;
    .navigation {
      flex: 1 1 488px;
      .navbar.navbar-default {
        border: none;
        border-radius: 0;
        background: transparent;
        margin-bottom: 0;
        @media (max-width: 767px) {
          margin-left: -15px;
          margin-right: -15px;
          .navbar-brand {
            img {
              height: 32px;
              margin-top: -6px;
            }
          }
        }
        .navbar-collapse {
          padding-right: 0;
          padding-left: 0;
          > ul {
            position: relative;
            margin: 0;
            padding: 0;
            padding-left: 15px;
            > li {
              display: inline-block;
              padding: 14px 15px;
              margin-left: -1px;
              z-index: 99;
              @media (max-width: 767px) {
                display: block;
              }
              &:first-child {
                display: none;
              }
              &:nth-child(2) {
                padding: 14px 15px 14px 0;
              }
              > a {
                color: #000;
                font-size: 16px;
                padding: 0;
                margin: 0;
                display: block;
                &:hover, &:focus {
                  background: transparent;
                }
              }
              &:hover, &:focus, &.active {
                > a {
                  position: relative;
                  color: #4859a5;
                }
                &:first-child {
                  > a {
                    &:after {
                      top: -16px;
                    }
                  }
                }
              }
              &:first-child {
                a {
                  text-transform: uppercase;
                  display: none;
                }
              }
              &:nth-child(2) {
                a {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }
    .logo {
      flex: 2 1 125px;
      text-align: center;
      img {
        width: 74px;
      }
    }
    .contacts {
      display: flex;
      text-align: right;
      @media (min-width: 768px) and (max-width: 1199px) {
        text-align: right;
      }
      @media (max-width: 767px) and (orientation: landscape) {
        flex-basis: 50%;
        text-align: right;
      }
      @media (max-width: 767px) and (orientation: portrait) {
        flex-basis: 100%;
        text-align: center;
      }
      .phone {
        font-size: 16px;
        color: #000;
        line-height: 1.6;
        padding-right: 30px;
        @media (max-width: 1199px) {
          color: #fff;
        }
        @media (max-width: 767px) {
          color: #000;
        }
      }
      .mail {
        font-size: 16px;
        color: #000;
        line-height: 1.6;
        padding-right: 25px;
        @media (max-width: 1199px) {
          color: #fff;
        }
        @media (max-width: 767px) {
          color: #000;
        }
      }
      .btn-callback {
        a {
          color: #000;
          font-size: 16px;
          text-decoration: none !important;
          display: inline-block;
          position: relative;
          transition: color 0.2s ease-out 0s;
          &:after {
            font-family: "metpromstroy" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\70";
            font-size: 24px;
            color: #000;
            position: relative;
            top: -6px;
            right: -7px;
            transition: right 0.2s ease-out 0s;
          }
          &:hover {
            color: #4859a5;
            transition: color 0s ease-in 0.3s;
            &:after {
              right: -10px;
              transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s ;
            }
          }
          span {
            overflow: hidden;
            display: inline-block;
            position: relative;
            padding: 0 0 7px;
            &:before {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 68px;
              height: 1px;
              background-color: #000;
              transition: all 0.4s ease;
            }
            &:hover {
              &:before {
                left: 100%;
                background-color: #00b7f4;
                transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
              }
            }
          }
        }
      }
    }
  }
}