.wrapper-box {
  h3 {
    color: #4859a5;
    font-size: 32px;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .newsflash {
    display: flex;
    flex-wrap: nowrap;
    .news-item {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      .news-item-bg {
        display: flex;
        background: url(/templates/metpromstroy/images/bg-category.png);
        padding: 15px 15px 15px 0;
        &:after {
          content: "";
        }
        .news-item-more {
          font-size: 16px;
        }
        .news-item-box {
          padding-left: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}