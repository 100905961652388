.left-menu {
  li {
    &.open {
      > .accordeonck_outer {
        span {
          width: 0;
          height: 0;
          border: 8px solid transparent;
        }
        .toggler_icon {
          background: transparent !important;
          border-top-color: #85a1ca;
          border-bottom: 0;
        }
      }
    }
    > .accordeonck_outer {
      span {
        width: 0;
        height: 0;
        border: 8px solid transparent;
      }
      .toggler_icon {
        background: transparent !important;
        border-left-color: #2b3542;
        border-right: 0;
      }
    }
    &.parent {
      > span {
        span {
          &.toggler_icon {
            background: none !important;
            height: inherit !important;
            width: inherit !important;
            top: 10px;
          }
        }
      }
    }
    ul {
      padding-left: 20px;
      li {
        margin-left: -10px !important;
      }
    }
    a {
      padding-bottom: 5px !important;
      padding-left: 20px !important;
      padding-right: 2px !important;
      padding-top: 5px !important;
    }
  }
  .isactive {
    color: #85a1ca !important;
  }
}