.slider-wrapper {
  margin-top: 100px;
  .slider-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    outline: 0;
    .slider-box-info {
      width: 50%;
      .slider-name {
        font-size: 32px;
        color: #4859a5;
        text-align: center;
      }
      .slider-short-desctiption {
        font-size: 24px;
        margin-bottom: 25px;
      }
      .slider-description {
        font-size: 16px;
      }
      .slider-more {
        display: inline-block;
        font-size: 16px;
        position: relative;
        margin-bottom: 30px;
        margin-top: 30px;
        a {
          color: #000;
          font-size: 16px;
          text-decoration: none !important;
          display: inline-block;
          position: relative;
          transition: color 0.2s ease-out 0s;
          &:after {
            font-family: "metpromstroy" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\70";
            font-size: 24px;
            color: #000;
            position: relative;
            top: -6px;
            right: -7px;
            transition: right 0.2s ease-out 0s;
          }
          &:hover {
            color: #4859a5;
            transition: color 0s ease-in 0.3s;
            &:after {
              right: -10px;
              transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s ;
            }
          }
          span {
            overflow: hidden;
            display: inline-block;
            position: relative;
            padding: 0 0 7px;
            &:before {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 68px;
              height: 1px;
              background-color: #000;
              transition: all 0.4s ease;
            }
            &:hover {
              &:before {
                left: 100%;
                background-color: #00b7f4;
                transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
              }
            }
          }
        }
      }
    }
    .slider-box-img {
      width: 50%;
      .slider-img {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}