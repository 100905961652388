#map {
  #ya-map {
    height: 670px;
  }
  .container {
    position: relative;
    .map-box {
      position: absolute;
      background: #fff;
      padding: 40px 30px;
      border: 1px solid #ececec;
      box-shadow: 0 0 1px #ececec;
      border-radius: 6px;
      margin-top: -576px;
      left: calc(50% + 140px);
      height: 480px;
      .image {
        margin-left: -30px;
        margin-right: -30px;
        img {
          width: 100%;
        }
      }
      .address {
        font-size: 25px;
        color: #0a0a0a;
        padding-left: 30px;
        position: relative;
        margin-top: 20px;
        &:before {
          position: absolute;
          left: 0;
          top: 5px;
          content: "\76";
          font-family: "treid" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: #85a1ca;
        }
        span {
          display: block;
          font-size: 20px;
        }
      }
      .contacts {
        padding-left: 30px;
        margin-top: 20px;
        position: relative;
        &:before {
          font-size: 25px;
          position: absolute;
          left: 0;
          top: 5px;
          content: "\77";
          font-family: "treid" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: #85a1ca;
        }
        a {
          font-size: 20px;
          color: #0a0a0a;
          display: block;
          .work-time {
            font-size: 12px;
            color: #757575;
          }
        }
      }
      .btn-call-back {
        margin-top: 35px;
        font-size: 20px;
        font-weight: 300;
        padding: 12px 40px;
        color: #fff;
        background-color: #85a1ca;
        line-height: 1;
      }
      @media (max-width: 991px) {
        position: static;
        left: 0;
        margin: 0;
        height: auto;
        padding: 30px 0;
        box-shadow: none;
        border: none;
        .image {
          display: none;
        }
      }
    }
  }
}