.about {
  h3 {
    color: #4859a5;
    font-size: 32px;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .wrapper-about {
    display: flex;
    .about-item {
      width: 33.33%;
      display: flex;
      .icon-fast-delivery {
        font-size: 48px;
        color: #00b7f4;
      }
      .icon-money {
        font-size: 48px;
        color: #00b7f4;
      }
      .icon-certificat {
        font-size: 48px;
        color: #00b7f4;
      }
      .about-text {
        font-size: 18px;
        padding: 15px;
      }
    }
  }
}