.contacts {
  .wrapper-contact {
    display: flex;
    .contacts-block {
      width: 25%;
      h3 {
        font-size: 32px;
        color: #4859a5;
        margin: 0;
        padding-bottom: 75px;
      }
      .box-info-left {
        .top-block {
          font-size: 16px;
          color: #000;
          margin-bottom: 55px;
          margin-left: 55px;
          @media (max-width: 767px) and (orientation: portrait) {

          }
          h3 {
            font-size: 18px;
            color: #a0a7ae;
          }
          a {
            font-size: 16px;
            color: #000;
            &:hover {
              text-decoration: none;
            }
          }
          p {
            font-size: 12px;
            color: #a0a7ae;
          }
        }
        .adres, .phones, .mails {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: 44px;
            height: 2px;
            display: block;
            background: #000;
            left: -54px;
            top: calc(50% - 1px);
          }
        }
      }
    }
    .wrapper-map {
      width: 75%;
      .bg-map {
        position: relative;
        .bg-map-top {
          height: 50%;
          width: 50%;
          position: absolute;
          background: url(/templates/metpromstroy/images/bg-category.png) !important;
        }
        .adr-map {
          padding: 35px 97px;
          position: relative;
          z-index: 99;
        }
        .bg-map-bottom {
          bottom: 0;
          right: 0;
          height: 50%;
          width: 50%;
          position: absolute;
          background: url(/templates/metpromstroy/images/bg-category.png) !important;
        }
      }
    }
  }
  .info-bottom {
    margin-top: 15px;
    .box-info {
      display: flex;
      .manager {
        width: calc((100% / 3) - 15px);
        h3 {
          color: #4859a5;
          font-size: 18px;
          margin: 0;
        }
        span {
          display: block;
          font-size: 16px;
          color: #000;
        }
        a {
          display: block;
          font-size: 16px;
          color: #000;
          &:hover {
            text-decoration: none;
            color: #4859a5;
          }
        }
      }
    }
  }
  .callback {
    position: relative;
    a {
      color: #000;
      font-size: 16px;
      text-decoration: none !important;
      display: inline-block;
      position: relative;
      transition: color 0.2s ease-out 0s;
      &:after {
        font-family: "metpromstroy" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\70";
        font-size: 24px;
        color: #000;
        position: relative;
        top: -6px;
        right: -7px;
        transition: right 0.2s ease-out 0s;
      }
      &:hover {
        color: #4859a5;
        transition: color 0s ease-in 0.3s;
        &:after {
          right: -10px;
          transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s;
        }
      }
      span {
        overflow: hidden;
        display: inline-block;
        position: relative;
        padding: 0 0 7px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 68px;
          height: 1px;
          background-color: #000;
          transition: all 0.4s ease;
        }
        &:hover {
          &:before {
            left: 100%;
            background-color: #00b7f4;
            transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
          }
        }
      }
    }
  }
}