#products {
  background: url("/templates/tradeindustriya/images/bg_production.jpg") center center no-repeat;
  padding-top: 110px;
  padding-bottom: 185px;
  @media (max-width: 767px) {
    background: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .slick-next:before, .slick-prev:before {
    font-size: 28px;
  }
  .slick-next {
    right: 5px;
    bottom: 45px;
    top: auto;
    z-index: 2;
  }
  .slick-prev {
    left: 5px;
    top: 45px;
    z-index: 2;
  }
  .block-products {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      flex-wrap: nowrap;
      margin-left: -15px !important;
      margin-right: -15px !important;
    }
    .block-product {
      margin-bottom: -75px;
      background-position: center;
      background-repeat: no-repeat;
      min-width: 334px;
      width: 334px;
      min-height: 334px;
      height: 334px;
      margin-left: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 334px;
      @media (max-width: 767px) and (orientation: portrait) {
        min-width: 0;
        width: calc(100% + 30px);
        margin-left: -15px !important;
        margin-right: -15px !important;
      }
      &:hover {
        position: relative;
        z-index: 1;
      }
      &:nth-child(3) {
        margin-left: 0;
      }
      &:nth-child(5) {
        margin-right: 0;
      }
      &.block-product-1 {
        background-image: url("/templates/tradeindustriya/images/bg_brod_sp.png");
      }
      &.block-product-2 {
        background-image: url("/templates/tradeindustriya/images/bg_brod_t.png");
      }
      &.block-product-3 {
        background-image: url("/templates/tradeindustriya/images/bg_brod_lp.png");
      }
      &.block-product-4 {
        background-image: url("/templates/tradeindustriya/images/bg_brod_mim.png");
      }
      &.block-product-5 {
        background-image: url("/templates/tradeindustriya/images/bg_brod_ks.png");
      }
      &.block-product-6 {
        background-image: url("/templates/tradeindustriya/images/bg_brod_cm.png");
      }
      &.block-product-7 {
        background-image: url("/templates/tradeindustriya/images/bg_brod_ns.png");
      }
      @media (max-width: 1199px) {
        margin-bottom: 0;
        margin-left: 5px;
        margin-right: 5px;
        &:nth-child(3) {
          margin-left: 5px;
        }
        &:nth-child(5) {
          margin-right: 5px;
        }
      }
      &.slick-active {
        z-index: 1;
        position: relative;
      }
      .name {
        display: block;
        text-align: center;
        a {
          text-transform: uppercase;
          font-size: 24px;
          color: #fff;
          text-decoration: none;
          display: block;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .text {
        display: none;
        text-align: center;
        padding: 15px;
        a {
          color: #2b3542;
          font-size: 16px;
          line-height: 24px;
          text-decoration: none;
          display: block;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &:hover {
        @media (min-width: 992px) {
          background-image: url("/templates/tradeindustriya/images/bg_brod_hover.png");
          .text {
            display: block;
          }
          .name {
            display: none;
          }
        }
      }
    }
  }
}