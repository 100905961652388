@media (min-width: 768px) {
  .modal-sm {
    width: 480px;
  }
}

.modal-dialog {
  text-align: left;
  margin-top: 120px;
  .modal-header {
    padding: 35px 0 75px 102px;
    border: none;
    text-align: left;
    color: #4859a5;
    .modal-title {
      line-height: normal;
      font-size: 26px;
      display: inline-block;
    }
    .close {
      margin-top: -15px;
      font-size: 16px;
      right: 18px;
      position: relative;
      color: #000;
      opacity: 1;
      &:hover {
        color: #000;
        opacity: 0.5;
      }
      i {
        font-size: 24px;
      }
    }
  }
  .modal-content {
    border: none;
    border-radius: 0;
    .modal-body {
      padding: 18px 48px 38px 102px;
      font-size: 12px;
      font-weight: 100;
      font-family: Roboto, sans-serif;
      .form-control {
        color: #7d7d7d;
        background: url(/templates/metpromstroy/images/bg-category.png);
        border-bottom-color: transparent;
        border-radius: 0;
        box-shadow: none;
        transition: none;
        border-top: none;
        border-right: none;
        border-left: none;
        border-width: 1px;
        font-size: 16px;
        text-align: left;
      }
      .error {
        border-bottom-color: #ca2500;
      }
      .pad-callback {
        padding-bottom: 0;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        align-items: baseline;
        .callback-label {
          font-size: 14px;
          color: #000;
          flex-basis: 77px;
          &:after {
            content: '';
            width: 40px;
            height: 1px;
            background: #000;
            position: absolute;
            bottom: 0;
            left: 0;
          }
          label {
            display: inherit;
            max-width: none;
            margin-bottom: 0;
            font-weight: 300;
          }
        }
        .callback-input {
          flex-basis: 246px;
        }
      }
      .checkbox input[type=checkbox] {
        margin-top: 1px;
      }
      .btn-callbacks {
        background-color: transparent;
        border-color: transparent;
        font-size: 16px;
        font-weight: 300;
        display: inline-block;
        transition: all .3s linear;
        padding: 0;
        &:hover {
          background-color: transparent;
          border-color: transparent;
        }
      }
      .info-send {
        padding-bottom: 50px;
        p {
          font-size: 16px;
          text-indent: 0;
          margin: 0;
        }
      }
      #callback_tel {
        .closed {
          position: relative;
          left: 35px;
          opacity: 1;
          padding: 0;
          cursor: pointer;
          background: 0 0;
          border: 0;
          float: right;
          font-size: 24px;
          &:focus {
            outline: 0;
            outline-offset: 0;
            text-decoration: none;
          }
          &:hover {
            color: #000;
            opacity: .5;
          }
        }
        .modal-header {
          padding: 100px 0 35px 0;
          text-align: left;
          .modal-title {
            color: #4859a5;
            font-size: 32px;
          }
        }
        .box-close {
          text-align: left;
          display: inline-block;
          position: relative;
          transition: color .2s ease-out 0s;
          label {
            overflow: hidden;
            display: inline-block;
            position: relative;
            padding: 0 0 7px;
            &:before {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 68px;
              height: 1px;
              background-color: #000;
              transition: all 0.4s ease;
            }
            &:hover {
              &:before {
                left: 100%;
                background-color: #4859a5;
                transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
              }
            }
          }
          input[type="submit"] {
            background-color: transparent;
            border-color: transparent;
            font-size: 16px;
            font-weight: 300;
            color: #000;
            padding: 0;
            border: none;
            &:hover {
              color: #4859a5;
            }
          }
          &:after {
            font-family: "metpromstroy" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\70";
            font-size: 24px;
            color: #000;
            position: relative;
            top: -10px;
            right: -7px;
            transition: right 0.2s ease-out 0s;
          }
          &:hover {
            color: #4859a5;
            transition: color 0s ease-in 0.3s;
            &:after {
              right: -10px;
              transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s;
            }
          }
        }
      }
      .checkbox {
        text-align: left;
        font-size: 14px;
        margin-top: 80px;
      }
      .box-submit {
        text-align: left;
        display: inline-block;
        position: relative;
        transition: color .2s ease-out 0s;
        label {
          overflow: hidden;
          display: inline-block;
          position: relative;
          padding: 0 0 7px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 68px;
            height: 1px;
            background-color: #000;
            transition: all 0.4s ease;
          }
          &:hover {
            &:before {
              left: 100%;
              background-color: #4859a5;
              transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
            }
          }
        }
        input[type="submit"] {
          background-color: transparent;
          border-color: transparent;
          font-size: 16px;
          font-weight: 300;
          color: #000;
          padding: 0;
          border: none;
          &:hover {
            color: #4859a5;
          }
        }
        &:after {
          font-family: "metpromstroy" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\70";
          font-size: 24px;
          color: #000;
          position: relative;
          top: -10px;
          right: -7px;
          transition: right 0.2s ease-out 0s;
        }
        &:hover {
          color: #4859a5;
          transition: color 0s ease-in 0.3s;
          &:after {
            right: -10px;
            transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s;
          }
        }
      }
    }
  }
}