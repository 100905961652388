#recommend {
  padding-top: 60px;
  padding-bottom: 80px;
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .mails {
    display: flex;
    margin-top: 60px;
    align-items: center;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .slider-mail {
      width: 670px;
      background: url("/templates/tradeindustriya/images/bg_recommendations.jpg") center center no-repeat;
      padding: 70px 135px;
      margin-left: -15px;
      margin-right: 25px;
      @media (max-width: 767px) {
        display: none;
      }
      .slider {
        height: 464px;
        .slick-slide {
          width: 400px;
        }
      }
      .slick-slide img {
        margin: 0 auto;
      }
      .slick-next {
        right: -60px;
      }
      .slick-prev {
        left: -60px;
      }
      .slick-next:before, .slick-prev:before {
        font-size: 28px;
        color: #fff;
      }
    }
    .by-general {
      padding: 25px;
      p {
        text-indent: 30px;
      }
    }
  }
}