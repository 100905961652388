.catalog-products {
  padding-top: 50px;
  h3 {
    color: #4859a5;
    font-size: 32px;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .pop_categories {
    display: flex;
    flex-wrap: wrap;
    .item {
      flex: 1 1 420px;
      margin: 0 67px 0 68px;
      @media (max-width: 767px) {
        margin: 0 30px 0 30px;
      }
      .module {
        background-image: url("/templates/metpromstroy/images/bg-category.png");
        margin-bottom: 85px;
        .mod-wrapper {
          .mod-content {
            padding: 12px 30px 0 0;
            margin-bottom: -28px;
            a {
              font-size: 100px;
              color: #4859a5;
              text-align: right;
              &:hover {
                text-decoration: none;
              }
            }
          }
          .header {
            margin-bottom: 15px;
            right: 30px;
            position: relative;
            a {
              color: #000;
              font-size: 16px;
              text-decoration: none !important;
              display: inline-block;
              position: relative;
              transition: color 0.2s ease-out 0s;
              &:after {
                font-family: "metpromstroy" !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                speak: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\70";
                font-size: 24px;
                color: #000;
                position: relative;
                top: -6px;
                right: -7px;
                transition: right 0.2s ease-out 0s;
              }
              &:hover {
                color: #4859a5;
                transition: color 0s ease-in 0.3s;
                &:after {
                  right: -10px;
                  transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s;
                }
              }
              span {
                overflow: hidden;
                display: inline-block;
                position: relative;
                padding: 0 0 7px;
                &:before {
                  content: '';
                  display: block;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 68px;
                  height: 1px;
                  background-color: #000;
                  transition: all 0.4s ease;
                }
                &:hover {
                  &:before {
                    left: 100%;
                    background-color: #00b7f4;
                    transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}