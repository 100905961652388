.pagination {
  display: block;
  .counter {
    display: none;
  }
  ul {
    list-style: none;
    text-align: center;
    li {
      padding: 5px;
      display: inline-block;
      font-size: 12px;
      span {
        color: #000;
        display: block;
        /*padding: 8px;*/
        text-align: center;
        position: relative;
        text-decoration: underline;
        //z-index: 2;
        &:before {
          //position: absolute;
          //z-index: 1;
          /*font-family: "metpromstroy" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\64";
          font-size: 21px;
          color: #2b3542;
          top: 5px;
          left: 0;
          z-index: -1;*/
          text-decoration: underline;
        }
      }
      a {
        text-decoration: none;
        color: #2b3542;
        display: block;
        &:hover {
          text-decoration: none;
          i {
            text-decoration: none;
            color: #00b7f4;
          }
        }
        i {
          font-size: 24px;
          color: #000;
          position: relative;
          top: 7px;
          &:hover {
            text-decoration: none;
            color: #00b7f4;
          }
        }
      }
      &.prev, &.next {
        a {
          color: #2b3542;
          display: block;
          padding: 8px;
          border-radius: 6px;
          height: 28px;
          text-align: center;
        }
      }
    }
  }
}