@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&subset=cyrillic');

@font-face {
  font-family: "metpromstroy";
  src:url("/templates/metpromstroy/fonts/metpromstroy.eot");
  src:url("/templates/metpromstroy/fonts/metpromstroy.eot?#iefix") format("embedded-opentype"),
  url("/templates/metpromstroy/fonts/metpromstroy.woff") format("woff"),
  url("/templates/metpromstroy/fonts/metpromstroy.ttf") format("truetype"),
  url("/templates/metpromstroy/fonts/metpromstroy.svg#metpromstroy") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "metpromstroy" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "metpromstroy" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\61";
}
.icon-arrow-left:before {
  content: "\62";
}
.icon-pipes:before {
  content: "\63";
}
.icon-pipes-in-isolation:before {
  content: "\64";
}
.icon-sorting:before {
  content: "\65";
}
.icon-grid:before {
  content: "\66";
}
.icon-home:before {
  content: "\67";
}
.icon-faucet:before {
  content: "\68";
}
.icon-fast-delivery:before {
  content: "\69";
}
.icon-eye:before {
  content: "\6a";
}
.icon-factory:before {
  content: "\6b";
}
.icon-delivery:before {
  content: "\6c";
}
.icon-cross:before {
  content: "\6d";
}
.icon-certificat:before {
  content: "\6e";
}
.icon-arrow-up:before {
  content: "\6f";
}
.icon-arrow-right:before {
  content: "\70";
}
.icon-manometer:before {
  content: "\71";
}
.icon-logo:before {
  content: "\72";
}
.icon-istarweb:before {
  content: "\73";
}
.icon-menu:before {
  content: "\74";
}
.icon-metall:before {
  content: "\75";
}
.icon-metall-works:before {
  content: "\76";
}
.icon-money:before {
  content: "\77";
}
.icon-pipe-insulation:before {
  content: "\78";
}
.icon-pipes-1:before {
  content: "\79";
}
.icon-menu-icon:before {
  content: "\7a";
}
.icon-top:before {
  content: "\41";
}
.icon-nut:before {
  content: "\42";
}
.icon-pipe-ins:before {
  content: "\43";
}
.icon-circle:before {
  content: "\44";
}
.icon-circle-thin:before {
  content: "\45";
}
.icon-checkmark-round:before {
  content: "\46";
}
.icon-cross-1:before {
  content: "\47";
}