#advantages {
  background: url("/templates/tradeindustriya/images/bg_advantages.jpg") center center no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 80px;
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .advantages-box {
    margin-top: 55px;
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        width: 380px;
        .icon {
          width: 74px;
          margin-right: 25px;
          i {
            display: block;
            position: relative;
            width: 100%;
            height: 74px;
            &:before {
              position: absolute;
              z-index: 2;
              font-size: 48px;
              color: #fff;
              top: 12px;
              left: calc(50% - 22px);
            }
            &:after {
              position: absolute;
              z-index: 1;
              font-family: "treid" !important;
              font-style: normal !important;
              font-weight: normal !important;
              font-variant: normal !important;
              text-transform: none !important;
              speak: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\7a";
              font-size: 74px;
              color: #a0a7ae;
              top: 0;
              left: calc(50% - 37px);
              transition: all .3s linear;
            }
          }
        }
        .description {
          color: #fff;
          font-size: 16px;
          line-height: 24px;
          transition: all .3s linear;
        }
        &:hover {
          .icon {
            transition: all .3s linear;
            color: #85a1ca;
            i {
              &:after {
                transition: all .3s linear;
                color: #85a1ca;
              }
            }
          }
          .description {
            transition: all .3s linear;
            color: #85a1ca;
          }
        }
      }
    }
  }
}