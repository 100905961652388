.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  font-size: 16px;
  > li {
    &:nth-child(2) {
      a {
        font-size: 0;
        &:before {
          font-family: "metpromstroy" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\67";
          font-size: 16px;
        }
      }
    }
    + li {
      &:before {
        color: #000;
        content: " ";
        padding: 0;
      }
    }
    .divider {
      padding: 0 5px;
      &:first-child {
        padding: 0;
      }
    }
  }
  .active {
    color: #a0a7ae;
  }
}