#mxcpr {
  display: none;
}

.jshop {
  h1 {
    font-size: 30px;
  }
  .jshop_list_product {
    position: relative;
    @media (max-width: 767px) {
      margin-left: -15px;
      margin-right: -15px;
    }
    .table-products {
      border-collapse: collapse;
      width: 100%;
      th {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        padding: 21px;
        text-align: center;
        vertical-align: middle;
        border-right: 1px solid #4859a5;
        border-bottom: 2px solid #4859a5;
        @media (max-width: 767px) {
          padding: 15px;
        }
        &:first-child {
          border-left: 1px solid #4859a5;
        }
        &:last-child {
          border-right: none;
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #fff;
          &:nth-child(2n) {
            background: #f5f5f5;
          }
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
            border-bottom: 1px solid #4859a5;
          }
          &:hover {
            background: #00b7f4;
            cursor: pointer;
          }
          td {
            padding: 0 10px;
            vertical-align: middle;
            border-right: 1px solid #4859a5;
            font-size: 14px;
            @media (max-width: 767px) {
              font-size: 12px;
            }
            &.extra-field {
              text-align: center;
            }
            &.price {
              text-align: center;
              color: #4859a5;
            }
            .jshop-quick-order {
              text-align: center;
              .btn-inverse {
                overflow: hidden;
                display: block;
                margin: 6px 0;
                cursor: pointer;
                height: 40px;
                i {
                  font-size: 40px;
                  position: relative;
                  right: 0;
                  transition: right .3s ease-in-out;
                }
                &:hover {
                  i {
                    position: relative;
                    color: #fff;
                    right: -15px;
                  }
                }
              }
            }
            &:first-child {
              border-left: none;
            }
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
    .block_sorting_count_to_page {
      text-align: right;
      padding-bottom: 35px;
      .box_products_count_to_page {
        i {
          font-size: 24px;
          position: relative;
          top: 7px;
          padding-right: 10px;
        }
        a {
          color: #000;
          text-decoration: none;
          font-weight: 400;
          padding: 10px 5px;
          transition: all .1s linear;
          &.active {
            background-color: #4859a5;
            color: #fff;
          }
          &:first-child {
            margin-left: 5px;
          }
          &:hover {
            transition: all .1s linear;
            background-color: #4859a5;
            color: #fff;
          }
        }
      }
      select {
        border: none;
        option {
          &:hover {
            cursor: pointer;
          }
        }
      }
      #limit {
        appearance: none;
        background: url(/templates/metpromstroy/images/arrow-bottom.png) calc(100% - 10px) 50% no-repeat;
        width: 50px;
        outline: 0;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}